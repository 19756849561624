<template>
  <v-dialog
    scrollable
    v-model="dialog"
    max-width="600px"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Season</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="size-form">
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Number of Seasons"
                v-model="fields.total_seasons"
                type="text"
                outlined
                background-color="white"
                :error="errors.hasOwnProperty('total_seasons')"
                :error-messages="errors['total_seasons']"
              ></v-text-field>
            </v-col>
          </v-row>

          Duration to First Season
          <v-row class="mt-0">
            <v-col cols="6">
              <v-text-field
                label="Number*"
                v-model="fields.first_season_value"
                type="number"
                outlined
                background-color="white"
                :error="errors.hasOwnProperty('first_season_value')"
                :error-messages="errors['first_season_value']"
              ></v-text-field
            ></v-col>
            <v-col>
              <v-select
                label="Days/Months*"
                v-model="fields.first_season_unit"
                :items="units"
                outlined
                background-color="white"
                :error="errors.hasOwnProperty('first_season_unit')"
                :error-messages="errors['first_season_unit']"
              ></v-select>
            </v-col>
          </v-row>

          Duration Between Seasons
          <v-row class="mt-0">
            <v-col cols="6">
              <v-text-field
                label="Number*"
                v-model="fields.season_gap_value"
                type="number"
                outlined
                background-color="white"
                :error="errors.hasOwnProperty('season_gap_value')"
                :error-messages="errors['season_gap_value']"
              ></v-text-field
            ></v-col>
            <v-col>
              <v-select
                label="Days/Months*"
                v-model="fields.season_gap_unit"
                :items="units"
                outlined
                background-color="white"
                :error="errors.hasOwnProperty('season_gap_unit')"
                :error-messages="errors['season_gap_unit']"
              ></v-select>
            </v-col>
          </v-row>

          How far ahead would you like to see the Upcoming Seasons
          <v-row class="mt-0">
            <v-col cols="6">
              <v-text-field
                label="Number*"
                v-model="fields.forecast_upcoming_value"
                type="number"
                outlined
                background-color="white"
                :error="errors.hasOwnProperty('forecast_upcoming_value')"
                :error-messages="errors['forecast_upcoming_value']"
              ></v-text-field
            ></v-col>
            <v-col>
              <v-select
                label="Days/Months*"
                v-model="fields.forecast_upcoming_unit"
                :items="units"
                outlined
                background-color="white"
                :error="errors.hasOwnProperty('forecast_upcoming_unit')"
                :error-messages="errors['forecast_upcoming_unit']"
              ></v-select>
            </v-col>
          </v-row>

          How long does Pregnancy Last?
          <v-row class="mt-0">
            <v-col cols="6">
              <v-text-field
                label="Number*"
                v-model="fields.pregnancy_length_value"
                type="number"
                outlined
                background-color="white"
                :error="errors.hasOwnProperty('pregnancy_length_value')"
                :error-messages="errors['pregnancy_length_value']"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-select
                label="Days/Months*"
                v-model="fields.pregnancy_length_unit"
                :items="units"
                outlined
                background-color="white"
                :error="errors.hasOwnProperty('pregnancy_length_unit')"
                :error-messages="errors['pregnancy_length_unit']"
              ></v-select>
            </v-col>
          </v-row>

          <v-dialog
            scrollable
            v-model="varietiesDialog.open"
            max-width="400"
            @click:outside="resetVarietyDialog()"
          >
            <v-card>
              <v-card-title class="headline">
                {{ varietiesDialog.isEditing ? "Edit" : "New" }}
                Breeding/Resting Sequencer</v-card-title
              >
              <v-card-text class="pt-6 pb-0">
                <v-select
                  label="Title *"
                  v-model="varietiesDialog.fields.title"
                  :items="sequencer"
                  outlined
                  background-color="white"
                ></v-select>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" text @click="resetVarietyDialog()"
                  >Close</v-btn
                >
                <v-btn
                  color="accent"
                  text
                  type="submit"
                  @click="saveVarietyDialog()"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-data-table
            :headers="varietiesTable.headers"
            :items="grassesWithIndex"
            :disable-pagination="true"
            :hide-default-footer="true"
            no-data-text="There are no varieties"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title class="text-subtitle-1"
                  >Breeding/Resting Sequencer</v-toolbar-title
                >
                <v-spacer></v-spacer>
                <v-btn color="accent" small @click="openVarietyDialog()"
                  >Add Row</v-btn
                >
              </v-toolbar>
              <v-alert
                type="error"
                dense
                col="4"
                rounded="0"
                class="mb-0"
                v-if="errors.hasOwnProperty('varieties')"
                >{{ errors["varieties"].join(", ") }}</v-alert
              >
              <v-divider></v-divider>
            </template>

            <template v-slot:item.title="{ item }">
              <div style="white-space: pre-line" v-html="item.title"></div>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    text
                    icon
                    color="blue"
                    v-on="on"
                    class="mr-2"
                    @click="openVarietyDialog(item)"
                  >
                    <v-icon x-small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    text
                    icon
                    color="red"
                    v-on="on"
                    @click="deleteVariety(item.index)"
                  >
                    <v-icon x-small>mdi-archive</v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="size-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    breed: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      varietiesTable: {
        headers: [
          { text: "Title", value: "title", sortable: false },
          {
            text: "Actions",
            value: "actions",
            sortable: false,
            align: "right",
          },
        ],
        dialog: {
          open: false,
          loading: false,
        },
      },
      fields: {
        name: null,
        pregnancy_length_unit: null,
        pregnancy_length_value: null,
        forecast_upcoming_unit: null,
        total_seasons: null,
        first_season_value: null,
        first_season_unit: null,
        season_gap_value: null,
        season_gap_unit: null,
        forecast_upcoming_value: null,
        breeding_resting_sequence: [],
      },
      units: ["Days", "Months"],
      sequencer: ["Breeding", "Resting"],
      dialog: false,
      loading: false,
      isEditing: false,
      errors: {},

      varietiesDialog: {
        open: false,
        isEditing: false,
        title: {},
        fields: {
          title: null,
        },
      },
    };
  },

  computed: {
    grassesWithIndex() {
      return this.fields.breeding_resting_sequence.map((title, index) => ({
        ...title,
        index,
      }));
    },
  },

  mounted() {
    if (this.breed !== null) {
      if (this.breed.breeding_resting_sequence) {
        this.fields.breeding_resting_sequence =
          this.breed.breeding_resting_sequence;
      } else {
        this.fields.breeding_resting_sequence = [];
      }
    }
  },

  methods: {
    openVarietyDialog(title = null) {
      if (title !== null) {
        this.varietiesDialog.isEditing = true;
        this.varietiesDialog.title = title;
        this.varietiesDialog.fields.title = title.title;
      }

      this.varietiesDialog.open = true;
    },

    deleteVariety(index) {
      this.fields.breeding_resting_sequence.splice(index, 1);
    },

    resetVarietyDialog() {
      this.varietiesDialog.open = false;
      this.varietiesDialog.isEditing = false;
      this.varietiesDialog.title = {};
      this.varietiesDialog.fields.title = null;
    },

    saveVarietyDialog() {
      if (this.varietiesDialog.isEditing) {
        this.fields.breeding_resting_sequence[
          this.varietiesDialog.title.index
        ].title = this.varietiesDialog.fields.title;
      } else {
        this.fields.breeding_resting_sequence.push({
          title: this.varietiesDialog.fields.title,
        });
      }

      this.resetVarietyDialog();
    },

    openForm(breed = null) {
      if (breed !== null) {
        this.breed = breed;
        this.fields.name = breed.name;
        this.fields.total_seasons = breed.total_seasons;
        this.fields.first_season_value = breed.first_season_value;
        this.fields.first_season_unit = breed.first_season_unit;
        this.fields.season_gap_value = breed.season_gap_value;
        this.fields.season_gap_unit = breed.season_gap_unit;
        this.fields.forecast_upcoming_value = breed.forecast_upcoming_value;
        this.fields.forecast_upcoming_unit = breed.forecast_upcoming_unit;
        this.fields.pregnancy_length_value = breed.pregnancy_length_value;
        this.fields.pregnancy_length_unit = breed.pregnancy_length_unit;

        this.isEditing = true;
      }

      this.dialog = true;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        breedId: this.$route.params.breedId,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      this.$store
        .dispatch("lhl/breeds/saveBreed", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.fields.name = null;

      this.fields.name = null;
      this.fields.pregnancy_length_unit = null;
      this.fields.pregnancy_length_value = null;
      this.fields.forecast_upcoming_unit = null;
      this.fields.total_seasons = null;
      this.fields.first_season_value = null;
      this.fields.first_season_unit = null;
      this.fields.season_gap_value = null;
      this.fields.season_gap_unit = null;
      this.fields.forecast_upcoming_value = null;

      this.resetVarietyDialog();
    },
  },
};
</script>
