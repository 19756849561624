<template>
  <v-dialog
    scrollable
    v-model="dialog"
    max-width="600px"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >Breed a {{ breed.name }} with:</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="rules-form">
          <v-row
            v-for="(breed, index) in fields.outcomes"
            :key="index"
            class="pt-1 pb-1"
          >
            <v-col cols="6">
              {{ breed.breed_name }}

              <v-text-field
                v-model="fields.outcomes[index].breed_2"
                :value="breed.id"
                outlined
                v-show="false"
              >
                ></v-text-field
              >
            </v-col>

            <v-col cols="6">
              <v-select
                label="Outcome *"
                v-model="fields.outcomes[index].breed_outcome"
                :items="breeds"
                item-value="id"
                item-text="name"
                outlined
                background-color="white"
              ></v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="rules-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    breed: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      fields: {
        name: null,
        outcomes: [],
      },
      dialog: false,
      loading: false,
      isEditing: false,
      errors: {},
    };
  },

  computed: {
    breeds() {
      return this.$store.getters["lhl/breeds/all"];
    },
  },

  methods: {
    openForm(breedModel = null) {
      if (breedModel !== null) {
        let outcomes = [];

        if (breedModel.outcome.length > 0) {
          breedModel.outcome.map((item) => {
            item.breed_name = item.breed_second.name;
          });

          this.fields.outcomes = breedModel.outcome;

          this.breeds.forEach((breed) => {
            const index = breedModel.outcome.findIndex(
              (object) => object.breed_2 === breed.id
            );

            if (index === -1) {
              this.fields.outcomes.push({
                breed_2: breed.id,
                breed_outcome: null,
                breed_name: breed.name,
              });
            }
          });
        } else {
          this.breeds.forEach((breed) => {
            outcomes.push({
              breed_2: breed.id,
              breed_outcome: null,
              breed_name: breed.name,
            });
          });

          this.fields.outcomes = outcomes;
        }

        this.isEditing = true;
      }

      this.dialog = true;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        breedId: this.$route.params.breedId,
        fields: this.fields,
      };

      this.$store
        .dispatch("lhl/breeds/saveOutcome", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.fields.name = null;
      this.fields.outcomes = [];
    },
  },
};
</script>
