<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col>
          <h1>{{ breed.name }}</h1>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Dog Colours</div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.breedColor.openForm()"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>

            <v-data-table
              :headers="colorHeaders"
              :items="breed.colors"
              no-data-text="No Colours"
              :items-per-page="-1"
            >
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                      class="mr-2"
                      @click="$refs.breedColor.openForm(item)"
                    >
                      <v-icon x-small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>

                <v-btn
                  bottom
                  x-small
                  depressed
                  color="red lighten-4 red--text"
                  @click="openDelete(item, 'Color')"
                >
                  <v-icon x-small>mdi-archive</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Dog Markings</div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.breedMarkings.openForm()"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>

            <v-data-table
              :headers="colorHeaders"
              :items="breed.markings"
              no-data-text="No Markings"
              :items-per-page="-1"
            >
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                      class="mr-2"
                      @click="$refs.breedMarkings.openForm(item)"
                    >
                      <v-icon x-small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>

                <v-btn
                  bottom
                  x-small
                  depressed
                  color="red lighten-4 red--text"
                  @click="openDelete(item, 'Marking')"
                >
                  <v-icon x-small>mdi-archive</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Dog Coat Type</div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.breedCoatType.openForm()"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>

            <v-data-table
              :headers="colorHeaders"
              :items="breed.coat_types"
              no-data-text="No coat types"
              :items-per-page="-1"
            >
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                      class="mr-2"
                      @click="$refs.breedCoatType.openForm(item)"
                    >
                      <v-icon x-small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>

                <v-btn
                  bottom
                  x-small
                  depressed
                  color="red lighten-4 red--text"
                  @click="openDelete(item, 'Coat Type')"
                >
                  <v-icon x-small>mdi-archive</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Dog Sizes</div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.breedSize.openForm()"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add</span>
              </v-tooltip>
            </v-card-title>

            <v-divider></v-divider>

            <v-data-table
              :headers="colorHeaders"
              :items="breed.sizes"
              no-data-text="No sizes"
              :items-per-page="-1"
            >
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                      class="mr-2"
                      @click="$refs.breedSize.openForm(item)"
                    >
                      <v-icon x-small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>

                <v-btn
                  bottom
                  x-small
                  depressed
                  color="red lighten-4 red--text"
                  @click="openDelete(item, 'Size')"
                >
                  <v-icon x-small>mdi-archive</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Seasons Maintenance</div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.seasonsMaintenance.openForm(breed)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-card-title>

            <v-divider></v-divider>

            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <th>Number of Seasons</th>
                  <td>{{ breed.total_seasons }}</td>
                </tr>
                <tr>
                  <th>Duration to First Season</th>
                  <td>
                    {{ breed.first_season_value }} {{ breed.first_season_unit }}
                  </td>
                </tr>
                <tr>
                  <th>Duration between Seasons</th>
                  <td>
                    {{ breed.season_gap_value }} {{ breed.season_gap_unit }}
                  </td>
                </tr>
                <tr>
                  <th>Breeding/Resting Sequence</th>
                  <td>
                    <div
                      v-for="(item, index) in breed.breeding_resting_sequence"
                      :key="index"
                    >
                      {{ item.title }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>
                    How far ahead would you like to see the Upcoming Seasons
                  </th>
                  <td>
                    {{ breed.forecast_upcoming_value }}
                    {{ breed.forecast_upcoming_unit }}
                  </td>
                </tr>
                <tr>
                  <th>How long does Pregnancy Last?</th>
                  <td>
                    {{ breed.pregnancy_length_value }}
                    {{ breed.pregnancy_length_unit }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Breeding Rules</div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.breedingRules.openForm(breed)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-card-title>

            <v-divider></v-divider>

            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <th>Breed a {{ breed.name }} with:</th>
                  <th>Outcome</th>
                </tr>

                <tr v-for="breed in breed.outcome" v-bind:key="breed.id">
                  <td>{{ breed.breed_second.name }}</td>
                  <td>
                    <div v-if="breed.outcome">{{ breed.outcome.name }}</div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>

      <div></div>
      <BreedColor ref="breedColor" />
      <BreedSize ref="breedSize" />
      <BreedCoatType ref="breedCoatType" />
      <BreedMarkings ref="breedMarkings" />
      <BreedingRules ref="breedingRules" :breed="breed" />
      <SeasonsMaintenance ref="seasonsMaintenance" :breed="breed" />

      <v-dialog
        scrollable
        v-model="deleteBreed.dialog"
        persistent
        max-width="400"
      >
        <v-card>
          <v-card-title class="headline"
            >Archive {{ deleteBreed.item }}</v-card-title
          >
          <v-card-text>Are you sure you wish to archive?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" depressed @click="resetDelete"
              >No, cancel</v-btn
            >
            <v-btn
              color="success"
              depressed
              :loading="deleteBreed.loading"
              @click="saveDelete(deleteBreed.item)"
              >Yes</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import BreedColor from "./components/BreedColor";
import BreedSize from "./components/BreedSize";
import BreedCoatType from "./components/BreedCoatType";
import BreedMarkings from "./components/BreedMarkings";
import SeasonsMaintenance from "./components/SeasonsMaintenance";
import BreedingRules from "./components/BreedingRules";

export default {
  components: {
    BreedColor,
    BreedCoatType,
    BreedMarkings,
    SeasonsMaintenance,
    BreedingRules,
    BreedSize,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Lomond Hills Labradoodles",
          disabled: false,
          to: { name: "app-dashboard", params: { id: this.$route.params.id } },
        },
        {
          text: "Breed",
          disabled: false,
          exact: true,
          to: {
            name: "module-lhl-breeds",
            params: { id: this.$route.params.id },
          },
        },
      ],
      colorHeaders: [
        { text: "Name", value: "name" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      deleteAddressDialog: {
        open: false,
        error: false,
        addressLine1: "",
        addressId: null,
        loading: false,
      },
      addressDialog: {
        open: false,
        errors: {},
        fields: {
          line_1: "",
          line_2: "",
          line_3: "",
          city: "",
          county: "",
          postcode: "",
          country: "",
          is_shipping: false,
          is_billing: false,
        },
        loading: false,
        isEditing: false,
        address: {},
      },
      editCustomerDialog: {
        open: false,
        errors: {},
        first_name: "",
        last_name: "",
        email: "",
        phone: null,
        is_breeder: false,
        marketing_opt_in: false,
        loading: false,
      },
      snackbar: {
        open: false,
        text: "",
        timeout: 4000,
      },
      deleteBreed: {
        dialog: false,
        item: null,
        model: {},
        loading: false,
      },
      changingDelivery: null,
      changingBilling: null,
    };
  },

  computed: {
    breed() {
      let breed = this.$store.state.lhl.breeds["breed"];

      return breed;
    },
    breeds() {
      let breeds = this.$store.state.lhl.breeds["breeds"];

      return breeds;
    },
  },

  mounted() {},

  watch: {},

  methods: {
    openDelete(model, item) {
      this.deleteBreed.model = model;
      this.deleteBreed.dialog = true;
      this.deleteBreed.item = item;
    },

    resetDelete() {
      this.deleteBreed.dialog = false;
      this.deleteBreed.model = {};
      this.deleteBreed.loading = false;
      this.deleteBreed.item = null;
    },

    saveDelete(item) {
      this.deleteBreed.loading = true;

      let playloadUrl = null;
      let args = [];

      if (item == "Size") {
        playloadUrl = "lhl/breeds/deleteSize";
        args = {
          appId: this.$route.params.id,
          breedId: this.$route.params.breedId,
          sizeId: this.deleteBreed.model.id,
        };
      } else if (item == "Coat Type") {
        playloadUrl = "lhl/breeds/deleteCoatType";
        args = {
          appId: this.$route.params.id,
          breedId: this.$route.params.breedId,
          coatTypeId: this.deleteBreed.model.id,
        };
      } else if (item == "Color") {
        playloadUrl = "lhl/breeds/deleteColor";
        args = {
          appId: this.$route.params.id,
          breedId: this.$route.params.breedId,
          colorId: this.deleteBreed.model.id,
        };
      } else if (item == "Marking") {
        playloadUrl = "lhl/breeds/deleteMarking";
        args = {
          appId: this.$route.params.id,
          breedId: this.$route.params.breedId,
          markingId: this.deleteBreed.model.id,
        };
      }

      this.$store
        .dispatch(playloadUrl, args)
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteBreed.loading = false;
        });
    },
  },
};
</script>
